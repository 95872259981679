.manual-wrap {
  position: relative;
  z-index: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
  -webkit-transition: opacity ease 0.5s;
  transition: opacity ease 0.5s;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}
.manual-wrap.active {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}
@media screen and (min-width: 768px) {
  .humanities-main-panel {
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-image: url("../assets/humanities/Banner1.png");
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 50px;
  }
  .humanities-main-panel > div {
    max-width: 1200px;
    margin: 0 auto;
  }
  .humanities-main-panel > div .top-section {
    margin: 0;
    height: 328px;
    padding: 45px;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .humanities-main-panel > div .top-section .description {
    color: #fff;
    text-align: left;
    font-size: 23px;
    line-height: 50px;
    margin-bottom: 50px;
  }
  .humanities-main-panel > div .top-section .btn-wrapper {
    display: flex;
    padding: 2%;
    width: 70%;
    margin: auto;
    justify-content: space-between;
  }
  .humanities-main-panel > div .top-section .btn-wrapper .btn-item {
    cursor: pointer;
    display: inline-block;
    width: 200px;
    height: 60px;
    line-height: 60px;
    color: #cccccc;
    text-align: center;
    font-size: 19px;
    border: 1px solid #808180;
    border-radius: 5px;
    margin-right: 40px;
  }
  .humanities-main-panel > div .top-section .btn-wrapper .btn-item:hover {
    background: #fff;
    color: black;
  }
  .humanities-main-panel > div .info-wrapper {
    background: #fff;
    padding: 25px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
  }
  .humanities-main-panel > div .info-wrapper .info-item {
    display: flex;
    cursor: pointer;
  }
  .humanities-main-panel > div .info-wrapper .info-item:hover img {
    transform: rotateY(360deg);
    -webkit-transform: rotateY(360deg);
    -moz-transform: rotateY(360deg);
    -o-transform: rotateY(360deg);
    -ms-transform: rotateY(360deg);
  }
  .humanities-main-panel > div .info-wrapper .info-item > div:nth-of-type(1) {
    width: 30%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .humanities-main-panel > div .info-wrapper .info-item > div:nth-of-type(1) img {
    width: auto;
    height: auto;
    display: inline-block;
    display: block;
    transition: all 2s ease-in-out;
    -webkit-transition: all 2s ease-in-out;
    -moz-transition: all 2s ease-in-out;
    -o-transition: all 2s ease-in-out;
  }
  .humanities-main-panel > div .info-wrapper .info-item > div:nth-of-type(2) {
    width: 70%;
  }
  .humanities-main-panel > div .info-wrapper .info-item > div:nth-of-type(2) p {
    color: #666;
    font-size: 14px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    padding: 0 27px;
  }
  .humanities-main-panel > div .info-wrapper .info-item > div:nth-of-type(2) p a {
    display: inline-block;
    margin-left: 8px;
    color: #437fd9;
  }
  .humanities-main-panel > div .info-wrapper .info-item > div:nth-of-type(2) .title {
    display: block;
    text-align: left;
    margin-bottom: 12px;
    font-size: 17px;
  }
}
@media screen and (min-width: 200px) and (max-width: 768px) {
  .humanities-main-panel {
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-image: url("../assets/humanities/Banner1.png");
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 50px;
  }
  .humanities-main-panel > div {
    max-width: 372px;
    margin: 0 auto;
  }
  .humanities-main-panel > div .top-section {
    margin: 0;
    height: 328px;
    padding: 45px;
    background-color: rgba(0, 0, 0, 0.5);
    margin-top: 200px;
  }
  .humanities-main-panel > div .top-section .description {
    color: #fff;
    text-align: left;
    font-size: 14px;
    line-height: 35px;
    margin-bottom: 50px;
  }
  .humanities-main-panel > div .top-section .btn-wrapper {
    display: flex;
    padding: 2%;
    width: 112%;
    margin: auto;
    justify-content: space-between;
  }
  .humanities-main-panel > div .top-section .btn-wrapper .btn-item {
    cursor: pointer;
    display: inline-block;
    width: 200px;
    height: 60px;
    line-height: 60px;
    color: #cccccc;
    text-align: center;
    font-size: 14px;
    border: 1px solid #808180;
    border-radius: 5px;
    margin-right: 40px;
  }
  .humanities-main-panel > div .top-section .btn-wrapper .btn-item:hover {
    background: #fff;
    color: black;
  }
  .humanities-main-panel > div .info-wrapper {
    background: #fff;
    padding: 25px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 167px;
  }
  .humanities-main-panel > div .info-wrapper .info-item {
    display: flex;
    cursor: pointer;
  }
  .humanities-main-panel > div .info-wrapper .info-item:hover img {
    transform: rotateY(360deg);
    -webkit-transform: rotateY(360deg);
    -moz-transform: rotateY(360deg);
    -o-transform: rotateY(360deg);
    -ms-transform: rotateY(360deg);
  }
  .humanities-main-panel > div .info-wrapper .info-item > div:nth-of-type(1) {
    width: 30%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .humanities-main-panel > div .info-wrapper .info-item > div:nth-of-type(1) img {
    width: auto;
    height: auto;
    display: inline-block;
    display: block;
    transition: all 2s ease-in-out;
    -webkit-transition: all 2s ease-in-out;
    -moz-transition: all 2s ease-in-out;
    -o-transition: all 2s ease-in-out;
  }
  .humanities-main-panel > div .info-wrapper .info-item > div:nth-of-type(2) {
    width: 70%;
  }
  .humanities-main-panel > div .info-wrapper .info-item > div:nth-of-type(2) p {
    color: #666;
    font-size: 14px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    padding: 0 27px;
  }
  .humanities-main-panel > div .info-wrapper .info-item > div:nth-of-type(2) p a {
    display: inline-block;
    margin-left: 8px;
    color: #437fd9;
  }
  .humanities-main-panel > div .info-wrapper .info-item > div:nth-of-type(2) .title {
    display: block;
    text-align: left;
    margin-bottom: 12px;
    font-size: 17px;
  }
}
@media screen and (min-width: 768px) {
  .humanities-history > div {
    max-width: 1200px;
    margin: 20px auto;
    display: flex;
    flex-wrap: wrap;
  }
  .humanities-history > div #title-wrapper {
    width: 100%;
    height: auto;
  }
  .humanities-history > div .hei-auto {
    height: auto !important;
    margin-bottom: 40px;
  }
  .humanities-history > div .history-wrapper {
    width: 50%;
    height: 350px;
    position: relative;
  }
  .humanities-history > div .history-wrapper .title {
    color: black;
    font-size: 24px;
    padding: 20px;
    font-weight: 800;
    font-family: cursive;
    text-align: center;
  }
  .humanities-history > div .history-wrapper .subTitle {
    color: black;
    width: 70%;
    margin: auto;
    font-family: emoji;
    text-align: center;
  }
  .humanities-history > div .history-wrapper .desc-box {
    position: absolute;
    top: 20px;
    right: 30px;
    color: #fff;
    padding: 15px;
    min-width: 200px;
    min-height: 60px;
    border-radius: 2px;
    background-color: rgba(0, 0, 0, 0.4);
  }
  .humanities-history > div .history-wrapper .txtarea {
    padding: 30px 15px 15px 15px;
    text-align: center;
    font-size: 14px;
    height: auto;
    word-break: break-all;
  }
  .humanities-history > div .history-wrapper img {
    width: 100%;
    height: 100%;
  }
  .humanities-advantage .flex-box {
    display: flex;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 10px auto 50px auto;
  }
  .humanities-advantage .flex-box .left-imgBox {
    width: 45%;
    height: 500px;
    border-radius: 3px;
    overflow: hidden;
  }
  .humanities-advantage .flex-box .left-imgBox img {
    width: 100%;
  }
  .humanities-advantage .flex-box .right-infoBox {
    width: 55%;
    box-sizing: border-box;
    padding: 15px 30px;
  }
  .humanities-advantage .flex-box .right-infoBox .title {
    font-size: 24px;
    font-family: system-ui;
    margin-top: 25px;
  }
  .humanities-advantage .flex-box .right-infoBox .subTitle {
    font-family: emoji;
  }
  .humanities-advantage .flex-box .right-infoBox .content {
    font-family: emoji;
    line-height: 20px;
    margin: 10px 0px;
  }
}
@media screen and (min-width: 200px) and (max-width: 768px) {
  .humanities-history > div {
    max-width: 1200px;
    margin: 20px auto;
    display: flex;
    flex-wrap: wrap;
  }
  .humanities-history > div #title-wrapper {
    width: 100%;
    height: auto;
  }
  .humanities-history > div .hei-auto {
    height: auto !important;
    margin-bottom: 40px;
  }
  .humanities-history > div .history-wrapper {
    width: 50%;
    height: 200px;
    position: relative;
  }
  .humanities-history > div .history-wrapper .title {
    color: black;
    font-size: 24px;
    padding: 20px;
    font-weight: 800;
    font-family: cursive;
    text-align: center;
  }
  .humanities-history > div .history-wrapper .subTitle {
    color: black;
    width: 70%;
    margin: auto;
    font-family: emoji;
    text-align: center;
  }
  .humanities-history > div .history-wrapper .desc-box {
    position: absolute;
    top: 20px;
    right: 30px;
    color: #fff;
    padding: 15px;
    min-height: 60px;
    border-radius: 2px;
    background-color: rgba(0, 0, 0, 0.4);
  }
  .humanities-history > div .history-wrapper .txtarea {
    padding: 30px 15px 15px 15px;
    text-align: center;
    font-size: 14px;
    height: auto;
    word-break: break-all;
  }
  .humanities-history > div .history-wrapper img {
    width: 100%;
    height: 100%;
  }
}
.humanities-localSpecialty {
  position: relative;
}
.humanities-localSpecialty .localSpecialty-title {
  text-align: left;
  max-width: 1200px;
  margin: 8px auto;
  font-size: 24px;
  font-family: fantasy;
}
.humanities-localSpecialty .image-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.humanities-localSpecialty .image-wrapper .image-item {
  width: 30%;
  height: auto;
  margin-bottom: 50px;
}
.humanities-localSpecialty .image-wrapper .image-item > div:nth-of-type(1) {
  width: 100%;
  height: 225px;
  border-radius: 3px;
  overflow: hidden;
}
.humanities-localSpecialty .image-wrapper .image-item > div:nth-of-type(1):hover img {
  transform: scale(1.3);
  transition: all 1s ease 0s;
  -webkit-transform: scale(1.3);
  -webkit-transform: all 1s ease 0s;
}
.humanities-localSpecialty .image-wrapper .image-item > div:nth-of-type(1) img {
  width: 100%;
  height: 100%;
}
.humanities-localSpecialty .image-wrapper .image-item > div:nth-of-type(2) .title {
  color: black;
  font-size: 18px;
  font-weight: 700;
  font-family: serif;
  margin: 5px;
}
.humanities-localSpecialty .image-wrapper .image-item > div:nth-of-type(2) .content {
  font-family: serif;
  font-size: 15px;
  margin: 2px;
}
@media screen and (min-width: 200px) and (max-width: 768px) {
  .humanities-advantage .flex-box {
    display: flex;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 10px auto 50px auto;
  }
  .humanities-advantage .flex-box .left-imgBox {
    width: 100%;
    height: 250px;
    border-radius: 3px;
    overflow: hidden;
  }
  .humanities-advantage .flex-box .left-imgBox img {
    width: 100%;
    height: 250px;
  }
  .humanities-advantage .flex-box .right-infoBox {
    width: 100%;
    box-sizing: border-box;
    padding: 15px 30px;
  }
  .humanities-advantage .flex-box .right-infoBox .title {
    font-size: 24px;
    font-family: system-ui;
    margin-top: 25px;
  }
  .humanities-advantage .flex-box .right-infoBox .subTitle {
    font-family: emoji;
  }
  .humanities-advantage .flex-box .right-infoBox .content {
    font-family: emoji;
    line-height: 20px;
    margin: 10px 0px;
  }
  .humanities-localSpecialty {
    position: relative;
  }
  .humanities-localSpecialty .localSpecialty-title {
    text-align: left;
    max-width: 1200px;
    margin: 8px auto;
    font-size: 24px;
    font-family: fantasy;
  }
  .humanities-localSpecialty .image-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .humanities-localSpecialty .image-wrapper .image-item {
    width: 30%;
    height: auto;
    margin-bottom: 50px;
  }
  .humanities-localSpecialty .image-wrapper .image-item > div:nth-of-type(1) {
    width: 100%;
    height: 130px;
    border-radius: 3px;
    overflow: hidden;
  }
  .humanities-localSpecialty .image-wrapper .image-item > div:nth-of-type(1):hover img {
    transform: scale(1.3);
    transition: all 1s ease 0s;
    -webkit-transform: scale(1.3);
    -webkit-transform: all 1s ease 0s;
  }
  .humanities-localSpecialty .image-wrapper .image-item > div:nth-of-type(1) img {
    width: 100%;
    height: 100%;
  }
  .humanities-localSpecialty .image-wrapper .image-item > div:nth-of-type(2) .title {
    color: black;
    font-size: 18px;
    font-weight: 700;
    font-family: serif;
    margin: 5px;
  }
  .humanities-localSpecialty .image-wrapper .image-item > div:nth-of-type(2) .content {
    font-family: serif;
    font-size: 15px;
    margin: 2px;
  }
}
.home-overview {
  margin-bottom: 50px;
}
.home-overview .flex-overview {
  display: flex;
  flex-wrap: wrap;
  height: 500px;
  margin: 0 auto;
}
.home-overview .flex-overview #info {
  width: 31%;
  background: #9153533b;
  padding: 30px 15px;
}
.home-overview .flex-overview #info p:nth-of-type(1) {
  font-size: 24px;
  text-align: center;
}
.home-overview .flex-overview #info p:nth-of-type(2) {
  font-size: 19px;
  text-align: center;
}
.home-overview .flex-overview #info div:nth-of-type(1) {
  height: 80px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  padding-right: 5px;
  box-sizing: border-box;
}
.home-overview .flex-overview #info div:nth-of-type(2) {
  width: 50%;
  height: 35px;
  padding: 10px 5px;
  border: #fff 1px solid;
  border-radius: 3px;
  margin: auto;
  text-align: center;
}
.home-overview .flex-overview #info ~ div {
  width: 23%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}
.home-overview .flex-overview #info ~ div > div {
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-overview .flex-overview #info ~ div > div div p,
.home-overview .flex-overview #info ~ div > div div span {
  width: 100%;
  text-align: center;
}
.home-overview .flex-overview #info ~ div > div div p {
  font-size: 18px;
}
.home-overview .flex-overview #info ~ div > div div span {
  display: inline-block;
  font-size: 14px;
}
.home-overview .flex-overview #info ~ div img {
  width: 70%;
  display: inline-block;
  margin: auto;
}
.home-overview .needToKnow {
  height: auto;
  margin: 10px auto 50px auto;
}
.home-overview .needToKnow img {
  width: 100%;
}
